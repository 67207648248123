import React from 'react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const NotFound = () => (
  <div>
    <div>
      <h1>Sidan kunde inte hittas</h1>
      <LinkComponent to={'/'}>Tillbaka till sajten</LinkComponent>
    </div>
  </div>
)

export default NotFound
